<script setup lang="ts">
import { computed, watch, ref, onBeforeUnmount } from "vue";
import { useQRCode } from "@vueuse/integrations/useQRCode";
import { useIntersectionObserver } from "@vueuse/core";
import VSkeleton from "@magnit/core/src/components/VSkeleton/VSkeleton.vue";

interface IQrCodeProps {
  qrcode: string;
  size?: number;
  blur?: number;
  loaderSize?: "l" | "xl";
  loaderColor?: "primary" | "invert" | "inherit";
}

const props = withDefaults(defineProps<IQrCodeProps>(), {
  size: 320,
  blur: 8,
  loaderSize: "xl",
  loaderColor: "primary",
});

const emit = defineEmits<{
  visible: [];
}>();

const qrRef = ref<HTMLImageElement | null>(null);

const qrVisible = ref(false);

const { stop } = useIntersectionObserver(qrRef, ([{ isIntersecting }]) => {
  qrVisible.value = isIntersecting;
});

const qrcode = computed(() => {
  const qrcode = props.qrcode || "H0000000000000000T000000";

  return useQRCode(qrcode, {
    margin: 0,
    width: props.size,
  });
});

watch(
  () => props.qrcode,
  (next) => {
    if (next) {
      emit("visible");
    }
  },
);

onBeforeUnmount(() => {
  if (typeof stop === "function") {
    stop();
  }
});
</script>

<template>
  <div
    class="qr-code"
    :style="{
      width: `${size}px`,
      height: qrcode.value ? 'auto' : `${size}px`,
    }"
  >
    <img
      v-if="qrcode.value"
      ref="qrRef"
      class="qr-code__img"
      :class="{
        'qr-code_blur': !qrcode,
      }"
      :style="
        !props.qrcode
          ? {
            filter: `blur(${blur}px)`,
          }
          : {}
      "
      :src="qrcode.value"
      alt="QR Code"
    >
    <VSkeleton v-else :size="`${size}px`" :radius="4" />
  </div>
</template>

<style lang="postcss">
.qr-code {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  &_blur {
    opacity: 0.25;
  }

  &__img {
    max-width: 100%;
  }
}
</style>
